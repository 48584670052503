<template>
  <span>
    <v-btn v-if="tipoBtn == 1" color="info" small @click="GetPdf()" :loading="cargandoDatos" :disabled="!id">
      <v-icon left small>
        {{ icons.mdiFilePdfBox }}
      </v-icon>
      PDF
    </v-btn>

    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <a class="pr-1" v-bind="attrs" v-on="on" @click="GetPdf()">
          <v-icon color="primary">{{ icons.mdiFilePdfBox }}</v-icon>
        </a>
      </template>
      <span>PDF</span>
    </v-tooltip>
    <Pdf ref="pdfRef"></Pdf>
  </span>
</template>
<script>
import AjusteServices from '@/api/servicios/inventario/AjusteServices'
import { mdiFilePdfBox } from '@mdi/js'
import { ref } from '@vue/composition-api'
import FuncionesGenerales from '@/funciones/funciones'
import Pdf from '@/components/PdfFormatoLibreDocumentos.vue'
export default {
  props: {
    id: Number,
    tipoBtn: {
      type: Number,
      default: 1,
    },
  },
  components: {
    Pdf,
  },
  setup(props) {
    const cargandoDatos = ref(false)
    const pdfRef = ref(null)
    const GetPdf = () => {
      cargandoDatos.value = true
      let totalCosto = 0
      AjusteServices.ajusteListarPaginado({ id: props.id })
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            const datoInit = response.data.datos.datos[0]
            const cuerpoTablaDetalles = []

            datoInit.productos.forEach(item => {
              totalCosto += (item.costo * item.cantidad)
              cuerpoTablaDetalles.push([
                 item.producto.id.toString(), 
                 item.producto.nombre, 
                 item.producto.unidad_medida.descripcion,  
                 FuncionesGenerales.formatoNumeric(item.cantidad) ,
                 '$' + FuncionesGenerales.formatoNumeric(item.costo),   
                 FuncionesGenerales.formatoNumeric(item.cantidad * item.costo) 
              ])
            })
 
            const datosInit = {
              cabecera1: { visible: true },
              nombreDocumento: `Ajuste: ${datoInit.numero_documento}`,

              numeroDocumento: {
                texto: 'Ajuste:',
                valor: datoInit.id.toString(),
              },
              numeroControl: {
                texto: 'Doc Nro',
                valor: datoInit.numero_documento,
              },
              numeroFecha: {
                texto: 'Fecha Ajuste',
                valor: FuncionesGenerales.formatoFecha(datoInit.fecha_ajuste, 1),
              },
              sub1: {
                linea1: [
                  { texto: 'Area:', valor: datoInit.puesto_json.nombre },
                  { texto: 'Almacen:', valor: datoInit.almacen_json.descripcion },
                  {
                    texto: 'Estado:',
                    valor: datoInit.estado_json.descripcion,
                  },
                ],
                linea2: [
                  { texto: 'Fecha Creacion:', valor: FuncionesGenerales.formatoFecha(datoInit.fecha, 3)  },
                  { texto: 'Creado por:', valor: datoInit.empleado_json.nombre + ' ' + datoInit.empleado_json.apellido  },
                  { texto: 'Motivo:', valor: datoInit.motivo_json.descripcion  },
                ],
              },
              sub2: {
                linea1: [ 
                ],
                linea2: [ 
                ],
              },

              pie: {
                nota: {
                  texto: 'Descripcion:',
                  valor: datoInit.descripcion,
                },
                totales: [
                  {
                    texto: 'Numero de items:',
                    valor: cuerpoTablaDetalles.length.toString(),
                  },
                  {
                    texto: 'Total Costo:',
                    valor: '$' +  FuncionesGenerales.formatoNumeric(totalCosto),
                  },
                ],
              },

              detalles: {
                cabecera: [
                  { texto: 'Id', posicion: '45', alineacion: 'left' },
                  {
                    texto: 'Producto',
                    posicion: '80',
                    alineacion: 'left',
                    justificar: { maxWidth: 130, align: 'justify', maximoItems: 33 },
                  },
                  { texto: 'UndMed', posicion: '250', alineacion: 'left' },
                  { texto: 'Cant', posicion: '380', alineacion: 'right' },
                  { texto: 'Costo ', posicion: '480', alineacion: 'right' },
                  { texto: 'Total Costo', posicion: '555', alineacion: 'right' },
                ],
                cuerpo: cuerpoTablaDetalles,
              },
            }

            /*
            const datosInit = {
              nombreDocumento: `Ajuste: ${datoInit.numero_documento}`,

              cabecera1: {
                visible: true,
              },
              titulo: {
                visible: true,
                posicion: 'center',
                texto: 'Ajuste',
                TamanoLetras: 14,
                color: '#7B7B7B',
              },
              cabecera2: {
                visible: true,
                datos: [
                  { texto: '# ' + datoInit.numero_documento, TamanoLetras: 14, color: '#FF0000' },
                  { texto: 'Fecha Creacion: ' + FuncionesGenerales.formatoFecha(datoInit.fecha, 1) },
                  {
                    texto:
                      'Hora Creacion: ' +
                      FuncionesGenerales.formatoFecha(datoInit.fecha, 2)
                        .replace(/\u00a0/g, '')
                        .replace(/\u202f/g, ''),
                  },
                  { texto: 'Empleado: ' + datoInit.empleado_json.nombre + ' ' + datoInit.empleado_json.apellido },
                ],
              },
              sub1: {
                visible: true,
                datos: [
                  { texto: 'Fecha Ajuste: ' + FuncionesGenerales.formatoFecha(datoInit.fecha_ajuste, 1) },
                  { texto: 'Puesto: ' + datoInit.puesto_json.nombre },
                  { texto: 'Almacen: ' + datoInit.almacen_json.descripcion },
                ],
              },
              sub2: {
                visible: true,
                datos: [
                  { texto: 'Estado:' + datoInit.estado_json.descripcion },
                  { texto: 'Descripcion:' },
                  { texto: datoInit.descripcion },
                ],
              },

              cuerpo1: {
                visible: true,
                datos: [
                  {
                    titulo: { texto: 'Detalles', TamanoLetras: 12 },
                    columnStyles: {
                      3: {
                        halign: 'right',
                      },
                    },
                    tablas: {},
                    head: [
                      [
                        { content: 'id', styles: { halign: 'left' } },
                        { content: 'Producto', styles: { halign: 'left' } },
                        { content: 'Medida', styles: { halign: 'left' } },
                        { content: 'Costo', styles: { halign: 'right' } },
                        { content: 'Cantidad', styles: { halign: 'right' } },
                      ],
                    ],
                    body: cuerpoTablaDetalles,
                  },
                ],
              },
            }*/

            pdfRef.value.generarPDF(datosInit)
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          cargandoDatos.value = false
        })
    }

    return {
      GetPdf,
      cargandoDatos,
      pdfRef,
      icons: {
        mdiFilePdfBox,
      },
    }
  },
}
</script>