import { render, staticRenderFns } from "./SelectMotivo.vue?vue&type=template&id=66380688&"
import script from "./SelectMotivo.vue?vue&type=script&lang=js&"
export * from "./SelectMotivo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VAutocomplete,VDialog})
