<template>
  <v-card>
    <v-card-title>
      <span>Ajuste</span>
      <v-spacer></v-spacer>
      <v-btn v-if="modal == true" icon @click="cerrarModal()">
        <v-icon>{{ icons.mdiClose }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text v-if="cargando" class="text-center">
      <h4>Buscando la Ajuste...</h4>
    </v-card-text>
    <v-card-text v-if="!cargando && datos">
      <v-row>
        <v-col lg="8" md="8" sm="12" cols="12">
          <CabeceraEmpresa></CabeceraEmpresa>
        </v-col>
        <v-col lg="4" md="4" sm="12" cols="12">
          <h2 class="pt-12 pb-2">
            #
            <small>{{ datos.numero_documento }} </small>
          </h2>
          <span class="m-0 p-0"> Fecha creacion: {{ FuncionesGenerales.formatoFecha(datos.fecha, 1) }} </span>
          <br />
          <span class="m-0 p-0"> Hora creacion: {{ FuncionesGenerales.formatoFecha(datos.fecha, 2) }} </span>
          <br />
          <span class="m-0 p-0"> Fecha Ajuste: {{ FuncionesGenerales.formatoFecha(datos.fecha_ajuste, 1) }} </span>
          <br />
          <span class="m-0 p-0"> Empleado: {{ datos.empleado_json.nombre }} {{ datos.empleado_json.apellido }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="mt-4 mb-2">
        <v-col md="8" cols="12">
          <span class="m-0 p-0"> Puesto: {{ datos.puesto_json.nombre }}</span>

          <br />
          <span class="m-0 p-0"> Almacen: {{ datos.almacen_json.descripcion }}</span>

        </v-col>
        <v-col md="4" cols="12"> 
             
          <h4 class="m-0 p-0">Estado: {{ datos.estado_json.descripcion }}</h4>
     
          <span class="m-0 p-0"> Descripcion: {{ datos.descripcion }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Id</th>
                  <th class="text-left">Producto</th>
                  <th class="text-left">Medida</th>
                  <th class="text-right">Costo</th>
                  <th class="text-right">Cantidad</th>  
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in datos.productos" :key="i">
                  <td>{{ item.producto.id }}</td>
                  <td>{{ item.producto.nombre }}</td>
                  <td>{{ item.producto.unidad_medida.descripcion }}</td>
                  <td  class="text-right">{{ FuncionesGenerales.formatoNumeric(item.costo)  }}</td>
                  <td  class="text-right">{{ FuncionesGenerales.formatoNumeric(item.cantidad)  }}</td> 
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <br />
          <v-divider></v-divider>
        </v-col> 
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col cols="12" class="text-center mt-5">
          <BtnPdfAjuste :id="id" :tipoBtn="1"></BtnPdfAjuste>
          <v-btn
            v-if="VerButton == true"
            color="primary"
            class="ml-2"
            small
            :disabled="datos.estado == 37"
            @click="GetEditar(datos)"
          > 
            <v-icon left small>
              
              {{ icons.mdiPencil }}
            </v-icon>
            Editar
          </v-btn>
          <v-btn
            v-if="VerButton == true"
            color="error"
            class="ml-2"
            small
            :disabled="datos.estado == 37"
            @click="GetAnular(datos)"
          >
            <v-icon left small> {{ icons.mdiBlockHelper }} </v-icon>Anular
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
import CabeceraEmpresa from '@/components/CabeceraEmpresa.vue'
import FuncionesGenerales from '@/funciones/funciones'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import AjusteServices from '@/api/servicios/inventario/AjusteServices'
import BtnPdfAjuste from './BtnPdf.vue'
import store from '@/store'
import { mdiClose, mdiPrinter, mdiPencil, mdiCheck, mdiBlockHelper, mdiFilePdfBox } from '@mdi/js'
import funciones from '@/funciones/funciones'
export default {
  components: {
    CabeceraEmpresa,
    BtnPdfAjuste,
  },
  props: {
    id: Number,
    modal: Boolean,
    VerButton: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const cargando = ref(false)
    const datos = ref({})
    onBeforeMount(() => {
      cargar()
    })
    watch(props, () => {
      cargar()
    })

    const cerrarModal = () => {
      context.emit('GetCerrarModal')
    }
    const cargar = () => {
      cargando.value = true
      AjusteServices.ajusteListarPaginado({ id: props.id })
        .then(response => {
          if ((response.data.mensaje = 'BUSQUEDA_EXITOSA')) {
            if (response.data.datos.datos != null && response.data.datos.datos.length > 0) {
              datos.value = response.data.datos.datos[0]
            } else {
              store.commit('setAlert', {
                message: 'No hay resultado en la busqueda',
                type: 'warning',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(err => {
          store.commit('setAlert', {
            message: err,
            type: 'error',
                error: {
                        ...err, 
                        response: error?.response?.data
                    },
                funcion: 'cargar',
          })
        })
        .finally(() => {
          cargando.value = false
        })
    }

    const GetAnular = item => {
      context.emit('GetAnular', item)
    }
    const GetEditar = item => {
      context.emit('GetEditar', item)
    }
    const GetPdf = item => {
      context.emit('GetPdf', item)
    }
    const GetFinalizar = item => {
      context.emit('GetFinalizar', item)
    }

    return {
      datos,
      FuncionesGenerales,
      cerrarModal,
      cargar,
      icons: {
        mdiClose,
        mdiPrinter,
        mdiPencil,
        mdiCheck,
        mdiBlockHelper,
        mdiFilePdfBox,
      },
      GetAnular,
      GetEditar,
      GetPdf,
      GetFinalizar,
    }
  },
}
</script>