<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-subtitle> Ajuste </v-card-subtitle>
    <v-form ref="form" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" lg="6">
                <DatePicker v-model="datos.fecha_ajuste" :dense="true" :outlined="true" :label="'Fecha Ajuste'" />
                <SelectAlmacen
                class="mt-4"
                  :dense="true"
                  :outlined="true"
                  :add="false"
                  v-model="datos.almacen"
                  :filtro="{ id_puesto: store.state.puestoSelect.id_puesto }"
                />
                <SelectMotivo  class="mt-4" :dense="true" :outlined="true" :add="true" v-model="datos.motivo" />
              </v-col>
              <v-col cols="12" lg="6">
                <v-textarea v-model="datos.descripcion" class="mt-0" label="Descripcion" dense outlined></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
    <v-divider></v-divider>
    <v-card-subtitle> Detalle </v-card-subtitle>
    <v-divider></v-divider>

    <v-card-text>
      <v-form ref="formDetalle" lazy-validation>
        <v-row class="mb-4 mt-2">
          <v-col cols="12">
            <BuscarProducto
              :label="`Producto detalle`"
              @GetProducto="GetProductoDetalle"
              ref="RefProductoDetalle"
              :filtros="{ id_puesto: store.state.puestoSelect.id_puesto }"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Id</th>
              <th class="text-left">Producto</th>
              <th class="text-left">Costo</th>
              <th class="text-left">Actual</th>
              <th class="text-left">Cantidad</th>
              <th class="text-left">Final</th>
              <th class="text-left">-</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in datos.productos" :key="index">
              <td>
                {{ item.producto.id }}
              </td>
              <td>
                <strong> {{ item.producto.nombre }} </strong> <br />
                <small>{{ item.producto.unidad_medida.descripcion }}</small>
              </td>
              <td>$.{{ funciones.formatoNumeric(item.producto.costo) }}</td>
              <td>{{ funciones.formatoNumeric(item.actual) }}</td>
              <td>
                <v-text-field
                  style="width: 100px"
                  class="mt-1"
                  hide-details=""
                  v-model="item.cantidad"
                  dense
                  outlined
                  @keyup="changeCantidades(item, 'cantidad')"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  style="width: 100px"
                  class="mt-1"
                  hide-details=""
                  v-model="item.final"
                  dense
                  outlined
                  @keyup="changeCantidades(item, 'final')"
                ></v-text-field>
              </td>
              <td>
                <v-btn icon color="error" small @click="eliminarProdcuto(item)">
                  <v-icon small>{{ icons.mdiDelete }}</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <div class="text-center">
            <v-btn color="secondary" class="me-3" @click="limpiar()" small> Limpiar </v-btn>
            <v-btn color="primary" class="me-3" small @click="Guardar()"> Guardar </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import DatePicker from '@/components/DatePicker.vue'
import BuscarProducto from '@/views/sistema/inventario/producto/componentes/BuscarProductoExistencia.vue'
import SelectEstado from '@/views/sistema/administracion/archivo/estado/componentes/Select.vue'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import SelectAlmacenJson from '@/views/sistema/inventario/maestro/almacen/componentes/SelectJson.vue'
import funciones from '@/funciones/funciones'
import TooltipInfo from '@/components/TooltipInfo.vue'
import { mdiInformation, mdiDelete, mdiPencil } from '@mdi/js'
import AjusteServices from '@/api/servicios/inventario/AjusteServices'

import SelectMotivo from './SelectMotivo.vue'
export default {
  watch: {
    '$store.state.puestoSelect.id_puesto': function () {
      this.limpiar()
    },
    'datos.almacen': function () {
      this.cambiarAlmacen()
    },
  },
  components: {
    DatePicker,
    SelectEstado,
    SelectAlmacen,
    BuscarProducto,
    TooltipInfo,
    SelectAlmacenJson,
    SelectMotivo,
  },
  setup(props, context) {
    const guardando = ref(false)
    const form = ref(null)
    const formDetalle = ref(null)

    const RefProductoDetalle = ref(null)

    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
      numerico_requerido: [
        v => !!v || 'Es requerido',
        v => !isNaN(v) || 'No es numero valido',
        v => !(v == Infinity) || 'No es numero valido 2',
      ],
    }
    const datosInit = {
      id: -1,
      almacen: '',
      empleado: '',
      puesto: '',
      fecha_ajuste: '',
      productos: [],
      motivo: '',
      descripcion: '',
    }
    const itemAddInit = {
      cantidad: '0',
      existencia: '0',
      final: '0',
      producto: {},
    }
    const filtrarAlmacenCosto = item => {
      var exis = {}
      exis = item.existencias.find(it => it.id_almacen == datos.value.almacen)

      return exis
    }
    const datos = ref(
      JSON.parse(
        JSON.stringify({
          ...datosInit,
          puesto: store.state.puestoSelect.id_puesto,
          id_empleado: store.state.user.id_empleado,
        }),
      ),
    )
    const limpiar = () => {
      datos.value = JSON.parse(
        JSON.stringify({
          ...datosInit,
          puesto: store.state.puestoSelect.id_puesto,
          id_empleado: store.state.user.id_empleado,
        }),
      )
      itemAdd.value = JSON.parse(JSON.stringify(itemAddInit))

      form.value.resetValidation()
    }
    var editando = false

    const eliminarProdcuto = item => {
      datos.value.productos = datos.value.productos.filter(ele => ele.producto.id != item.producto.id)
    }
    const itemAdd = ref(JSON.parse(JSON.stringify(itemAddInit)))
    const cargandos = ref(false)
    const GetProductoDetalle = item => {
      console.log('GetProductoDetalle', item)
      if (editando == false) {
        if (item != undefined) {
          itemAdd.value = {
            id: item.id,
            cantidad: '0',
            existencia: '0',
            final: '0',
            producto: {
              id: item.id,
              nombre: item.nombre,
              costo: item.costo,
              existencias: item.existencias,
              unidad_medida: item.unidad_medida,
            },
          }
          agregarDetalle()
        }
      } else {
        editando = false
      }
    }
    const CargarEditar = item => {
      datos.value = {
        ...item,
        fecha_ajuste: funciones.formatoFecha(item.fecha_ajuste, 4),
      }
      goTo(0)
      cambiarAlmacen()
    }
    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }

    const agregarDetalle = () => {
      if (datos.value.productos.find(item => item.producto.id == itemAdd.value.producto.id) == undefined) {
        datos.value.productos.push({ ...itemAdd.value })
        itemAdd.value = JSON.parse(JSON.stringify(itemAddInit))
        RefProductoDetalle.value.limpiar()
        cambiarAlmacen()
      } else {
        store.commit('setAlert', {
          message: 'Ya existe el producto en la factura',
          type: 'warning',
        })
      }
    }
    const changeCantidades = (item, tipo) => {
      switch (tipo) {
        case 'cantidad':
          item.final = Number(item.actual) + Number(item.cantidad)
          break
        case 'final':
          item.cantidad = Number(item.final) - Number(item.actual)
          break
      }
    }
    const cambiarAlmacen = () => {
      datos.value.productos.forEach(element => {
        var exis = null

        exis = element.producto.existencias.find(it => it.id_almacen == datos.value.almacen)

        if (exis) {
          element.actual = Number(exis.existencia)
          element.final = Number(element.actual) + Number(element.cantidad)
        } else {
          element.actual = 0
          element.final = Number(element.cantidad)
        }
      })
    }
    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }

    const Guardar = () => {
      guardando.value = true
      if (validarForm()) {
        try {
          AjusteServices.AjusteActualizar({ ...datos.value, id_puesto: store.state.puestoSelect.id_puesto })
            .then(response => {
              console.log(response)
              if (response.data.mensaje == 'GUARDADO CON EXITO') {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
                ActualizarLista(response.data.datos)
                limpiar()
              } else {
                store.commit('setAlert', {
                  message: `${response.data.mensaje}`,
                  type: 'warning',
                })
              }
            })
            .catch(error => {
              console.log(error)
              store.commit('setAlert', {
                message: error,
                type: 'error',
                error: {
                        ...error, 
                        response: error?.response?.data
                    },
                funcion: 'Guardar',
              })
            })
            .finally(() => {
              guardando.value = false
            })
        } catch (error) {
          store.commit('setAlert', {
            message: error,
            type: 'error',
                error: error,
                funcion: 'Guardar',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }

    return {
      reglas,
      datos,
      CargarEditar,
      cargandos,
      limpiar,
      Guardar,
      form,
      ActualizarLista,
      store,
      GetProductoDetalle,
      RefProductoDetalle,
      itemAdd,
      formDetalle,
      agregarDetalle,
      icons: {
        mdiInformation,
        mdiDelete,
        mdiPencil,
      },
      eliminarProdcuto,
      funciones,
      filtrarAlmacenCosto,
      cambiarAlmacen,
      changeCantidades,
    }
  },
}
</script>